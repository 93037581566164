.outlined-button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 3px 9px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(64, 67, 64, 0.5);
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  text-transform: none;
}

.contained-button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 4px 10px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #008080;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  text-transform: none;
}

/* Stack of MUI - will turn to column at xs size */
.stack {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

fieldset {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
@media (min-width: 600px) {
  .outlined-button {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media only screen and (max-height: 639px) {
  fieldset[role='radiogroup'] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (min-height: 640px) {
  fieldset[role='radiogroup'] {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    align-items: center;
  }
}

@media (max-width: 599px) {
  fieldset > :not(style) + :not(style) {
    margin: 0;
    margin-top: 24px;
  }

  fieldset[role='radiogroup'] {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.mainRoot {
  display: grid;
  grid-template-rows: auto auto;
}

.questionHeader {
  height: 20vh;
  animation-duration: 1s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
}

.custom--h4 {
  font-size: clamp(1.25rem, 2.5vw, 1.5rem) !important;
  font-weight: 400;
}

.custom--h5 {
  font-size: clamp(1rem, 2.5vw, 1.5rem) !important;
  font-weight: 400;
  margin-top: 1rem;
}
.buttonOutlined {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 5px 15px !important;
}
.buttonContained {
  color: #fff !important;
  background-color: #008080 !important;
}

@media only screen and (max-width: 599px) {
  .questionContent {
    align-self: flex-start !important;
    margin-top: 2rem;
  }
  .ratingContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .bbcss--mainRoot {
    height: unset !important;
  }
  .intake--pageRoot {
    height: unset !important;
  }
}

@media only screen and (max-height: 962px) {
  fieldset > :not(style) + :not(style) {
    margin: 0;
    /* margin-top: 24px; */
  }

  .questionContent {
    align-self: flex-start !important;
    margin-top: 2rem;
  }
  .ratingContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .bbcss--mainRoot {
    height: unset !important;
  }
  .intake--pageRoot {
    height: unset !important;
    min-height: 60vh;
  }
}

@media only screen and (max-width: 960px) {
  .ratingContainer {
    display: flex;
    justify-content: space-evenly;
  }

  .customButton {
    width: 100%;
    flex-grow: 1;
  }
}

.questionContent {
  align-self: flex-end;
  padding-bottom: 3rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* buttons */
.floatRight {
  float: right;
}
.floatLeft {
  float: left;
}

/* rating */
.ratingContainer {
  display: flex;
  justify-content: space-evenly;
}

/* need to manually remove the question title highlight */
.sv-question__title--answer {
  background-color: transparent !important;
}

/* remove unneccessary header.. */
.sv-survey-header {
  font-size: 0;
}

.sv-body__progres {
  margin-bottom: 2.5rem !important;
  margin-top: 4rem !important;
}

.progressMargin {
  margin-left: 43%;
  padding-left: 0;
}

/* completed */
.completed_question {
  font-weight: 600;
  font-size: 18px !important;
  min-height: 0px;
}

.completed_panel {
  display: none;
}

.completed_panel_description {
  display: none;
}

.completed_rating {
  border: 0.1475em solid rgba(159, 159, 159, 0.158) !important;
  font-family: 'Roboto' !important;
  font-weight: 200 !important;
  vertical-align: middle !important;
  font-size: 14px;
  min-width: 2.3125em;
  height: 2.3125em;
  display: inline-block;
  padding: 0 0.3125em;
  text-align: center;
  line-height: 1.13;
  cursor: pointer;
  margin: 3px 0;
  margin-right: 0.26em;
  box-sizing: border-box;
  cursor: default;
}

.completed_rating span {
  margin-top: 0.6em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  display: inline-block;
}

.completed_rating span {
  color: rgba(159, 159, 159, 0.637) !important;
}

.completed_row {
  padding-bottom: 0px;
}

.completed_selected span {
  background-color: #90bbb9 !important;
  color: rgb(255, 255, 255) !important;
  border-color: #90bbb9 !important;
  display: inline-block;
}

/* intake form */
.intake--questionContent {
  animation-duration: 1s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  min-width: 70vw;
}
.intake--nav-button {
  color: #fff;
  background-color: #008080;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 6px 16px;
  font-size: 0.875rem;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.intake--checkbox-label {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}
.intake--checkbox-controlLabel {
  font-size: clamp(0.875rem, 1vw, 1rem);
}
.intake--checkbox-itemDecorator {
  margin: auto;
}
.intake--pageRoot {
  margin-left: 5%;
  margin-right: 5%;
  height: 60vh;
}

.intake--checkbox-root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.intake-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* tables */
.sv-table__cell {
  padding: 0.75rem 0 !important;
}
.sv-matrix__cell {
  min-width: 7em !important;
}
.sv-table__cell {
  padding: 0.75rem 0 !important;
}

h4 {
  margin: 0.25rem 0 0 0;
}

tr {
  display: flex;
  flex-direction: row;
  width: 100%;
}
td,
th {
  display: grid;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
}
thead {
  height: unset;
}

@media only screen and (max-width: 1280px) {
  thead {
    height: 100%;
  }
  table {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row-reverse;
  }
  tbody {
    display: flex;
    position: relative;
    align-items: stretch;
  }

  tr {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  th,
  td {
    flex-basis: 100%;
    display: flex;
    justify-content: flex-start;
  }
  th {
    font-weight: bold;
  }
  .sv-matrix__cell {
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
    padding: 0.625rem 0 0 0 !important;
  }
  .sv-matrix .sv-table__cell--header {
    padding: 0.83rem 0 0.415rem 0 !important;
  }
  /* .sv-item {
    margin: 0 auto;
  } */
}

#sv-nav-next {
  margin-left: auto;
}

.sv-selectbase {
  display: flex;
  flex-direction: column;
}

.sv-selectbase__decorator.sv-item__decorator {
  position: relative;
  left: 0;
}

.sv-page__description {
  min-height: unset;
  font-size: 1em;
  padding-left: 0.55em;
}

.sv-body__progress {
  margin-bottom: 3em !important;
}

.sv-root-modern .sv-progress__bar {
  background-color: #008080 !important;
}
